import { SVGProps } from "react";

const VascoLogo = (props: SVGProps<SVGSVGElement>) => (
  <svg width="110" height="50" viewBox="0 0 110 50" fill="none" {...props}>
    <path
      d="M0 16.1526H4.36555L9.99315 29.3883H10.0429L15.645 16.1526H20.0348L12.7406 32.4331C12.2569 33.4924 11.1717 34.1905 10.0072 34.1916C8.84177 34.1899 7.75636 33.4901 7.27378 32.4293L0 16.1526Z"
      fill="white"
    />
    <path
      d="M31.3636 15.7852C32.5271 15.785 33.6121 16.4809 34.097 17.5385L41.3938 33.8177H37.0066L31.4045 20.5833H31.3534L25.7233 33.8177H21.3564L28.6302 17.5411C29.1147 16.4827 30.1997 15.7857 31.3636 15.7852Z"
      fill="white"
    />
    <path
      d="M85.5731 27.51C84.8355 31.7205 81.2898 34.193 77.1202 34.193C71.7197 34.193 67.834 30.3647 67.834 24.9834C67.834 19.602 71.7197 15.7725 77.1202 15.7725C81.1231 15.7725 84.6243 18.075 85.5118 22.0841H81.5929C80.8273 20.212 79.2321 19.149 77.1266 19.149C73.8968 19.149 71.7006 21.5213 71.7006 24.9783C71.7006 28.4352 73.8968 30.8075 77.1266 30.8075C79.3699 30.8075 81.0735 29.6233 81.7282 27.4998L85.5731 27.51Z"
      fill="white"
    />
    <path
      d="M91.4297 24.9858C91.4297 19.6109 95.3167 15.7749 100.716 15.7749C106.115 15.7749 110 19.6109 110 24.9858C110 30.3607 106.118 34.1916 100.716 34.1916C95.3141 34.1916 91.4297 30.3633 91.4297 24.9858ZM106.142 24.9858C106.142 21.502 103.946 19.1566 100.716 19.1566C97.4861 19.1566 95.2912 21.502 95.2912 24.9858C95.2912 28.4696 97.4861 30.815 100.716 30.815C103.946 30.815 106.142 28.4683 106.142 24.9858Z"
      fill="white"
    />
    <path
      d="M57.1076 20.9203C56.8358 20.2299 55.672 19.1414 53.2461 19.1414C51.124 19.1414 49.8339 19.944 49.8339 21.0223C49.8339 22.0228 50.9313 22.7272 52.5877 23.0501L55.6197 23.5886C59.0843 24.2049 61.2869 25.795 61.4451 28.7211C61.6123 31.8003 58.9988 34.2389 54.0514 34.2389C48.6662 34.2389 46.3424 31.8143 45.6865 28.5654H49.6488C50.1975 29.9117 51.4941 30.8522 54.0514 30.8522C56.4045 30.8522 57.5989 30.0635 57.5989 28.8551C57.5989 28.0396 56.906 27.3237 55.233 27.0022L51.6217 26.3348C48.0116 25.6674 46.1255 23.8196 45.9813 21.15C45.8346 18.4561 48.3485 15.761 53.2666 15.761C57.6691 15.761 60.5212 17.8946 61.0699 20.9203H57.1076Z"
      fill="white"
    />
  </svg>
);

export default VascoLogo;
